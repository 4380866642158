import { Field } from 'formik'
import styled from 'styled-components'
import { darken } from 'polished'

export const StyledField = styled(Field)`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid ${props => props.haserror ? '#c03333' : '#c0c0c0'};
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
`

export const StyledErrorMessage = styled.p`
  color: #c03333;
  font-size: 0.9rem;
`

export const StyledSubmitButton = styled.button`
  margin-bottom: 2rem;
  padding: 0.75rem 4rem;
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 2rem;
  color: #FFF;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1.5rem;
  transition: background-color 200ms;

  &:focus,
  &:hover {
    background-color: ${props => darken(0.2, props.theme.colors.primary)};
  }

  &[disabled] {
    opacity: 0.75;
    cursor: default;
  }
`

const StatusMessage = styled.p`
  padding: 0.75rem 1rem;
  background-color: #FFF;
  border: 1px solid #a0a0a0;
  color: #a0a0a0;
  text-align: center;
`

export const SuccessMessage = styled(StatusMessage)`
  border-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.green};
`

export const ErrorMessage = styled(StatusMessage)`
  border: 1px solid ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.red};
`

export const SuggestEmailAddressButton = styled.button`
  margin: 0 0 2rem;
  padding: 0.75rem;
  background-color: ${props => props.theme.colors.primary};
  border: 0;
  color: #FFF;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1rem;
  outline: 0;
  text-align: center;
`

export const FormDelimter = styled.h2`
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  font-size: 1.2rem;
`
