import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { Flex } from 'rebass/styled-components'

import TextInput from '../TextInput'
import getValidationSchema from './getValidationSchema'
import { StyledSubmitButton, SuccessMessage, ErrorMessage } from './styles'

const NewsletterSubscribeForm = ({ t, apikey, apiURL, language }) => {
  const [submission, setSubmission] = useState({
    success: false,
    error: false,
    sending: false,
  })

  const newsletterSubscribeUrl = `${apiURL}/api/v1/newsletter-subscribe?apikey=${apikey}`

  const sendMail = (values, { resetForm }) => {
    setSubmission({
      ...submission,
      sending: true,
      success: false,
    })

    axios
      .put(newsletterSubscribeUrl, {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        note: values.note,
        language,
      })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setSubmission({
              sending: false,
              error: false,
              success: true,
            })
            resetForm()
          }
        }
      })
      .catch((error) => {
        setSubmission({
          error: error,
          success: false,
          sending: false,
        })
      })
  }

  const validationSchema = getValidationSchema(t)
  const submitDefaultLabel = t(
    'newsletter-subscribe:form.submit.defaultLabel',
    {
      defaultValue: 'Subscribe',
    },
  )
  const submitSendingLabel = t(
    'newsletter-subscribe:form.submit.sendingLabel',
    {
      defaultValue: 'Sending…',
    },
  )

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        note: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => {
        sendMail(values, formik)
      }}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Flex width="100%" flexDirection="column">
            <TextInput
              name="firstname"
              label={t('newsletter-subscribe:form.firstname.label', {
                defaultValue: 'First name',
              })}
              autoComplete="given-name"
              required
            />

            <TextInput
              name="lastname"
              label={t('newsletter-subscribe:form.lastname.label', {
                defaultValue: 'Last name',
              })}
              autoComplete="family-name"
              required
            />

            <TextInput
              name="email"
              label={t('newsletter-subscribe:form.email.label', {
                defaultValue: 'E-mail',
              })}
              type="email"
              autoComplete="email"
              required
            />

            <TextInput
              name="note"
              label={t('newsletter-subscribe:form.note.label', {
                defaultValue: 'Note',
              })}
            />

            <StyledSubmitButton
              type="submit"
              disabled={submission.sending || !dirty || !isValid}
            >
              {!submission.sending ? submitDefaultLabel : submitSendingLabel}
            </StyledSubmitButton>

            {submission.success && (
              <SuccessMessage>
                {t('newsletter-subscribe:form.state.success', {
                  defaultValue: 'Subscription successful!',
                })}
              </SuccessMessage>
            )}

            {submission.error && (
              <ErrorMessage>
                {t('newsletter-subscribe:form.state.error', {
                  defaultValue: 'Subscription failed! Please try again.',
                })}
              </ErrorMessage>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

NewsletterSubscribeForm.propTypes = {
  t: PropTypes.func.isRequired,
  apikey: PropTypes.string.isRequired,
  apiURL: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default withTranslation('newsletter-subscribe')(NewsletterSubscribeForm)
