import * as Yup from 'yup'

const getValidationSchema = (t) => {
  return Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required(
        t('signup:form.firstname.required', {
          defaultValue: 'First name is required',
        }),
      ),
    lastname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required(
        t('signup:form.lastname.required', {
          defaultValue: 'Last name is required',
        }),
      ),
    email: Yup.string()
      .email(
        t('signup:form.email.invalid', {
          defaultValue: 'Invalid email address',
        }),
      )
      .required(
        t('signup:form.email.required', {
          defaultValue: 'E-mail is required',
        }),
      ),
    note: Yup.string(),
  })
}

export default getValidationSchema
