import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Translation } from 'react-i18next'
import { Text } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import NewsletterSubscribeForm from '../components/NewsletterSubscribeForm'
import { withI18next } from 'gatsby-plugin-i18n'

const NewsletterSubscribePage = ({ data, pageContext }) => {
  const { language } = pageContext
  const { sitePlugin } = data
  const { pluginOptions } = sitePlugin
  const { apikey, apiURL } = pluginOptions

  return (
    <Translation>
      {(t) => (
        <Layout language={language}>
          <main>
            <LightSection>
              <Container maxWidth="420px">
                <SectionTitle as="h2">
                  {t('newsletter-subscribe:title', {
                    defaultValue: 'Newsletter',
                  })}
                </SectionTitle>

                <Text sx={{ mb: 4, color: 'primary', fontSize: '1.2rem' }}>
                  Subscribe to our newsletter.
                </Text>

                <NewsletterSubscribeForm
                  t={t}
                  apikey={apikey}
                  apiURL={apiURL}
                  language={language}
                />
              </Container>
            </LightSection>
          </main>
        </Layout>
      )}
    </Translation>
  )
}

NewsletterSubscribePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default withI18next()(NewsletterSubscribePage)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        # ns: { in: ["signup", "text-input"] }
      }
    ) {
      ...LocalLocaleFragment
    }
    sitePlugin(name: { eq: "gatsby-theme-sarvasya" }) {
      pluginOptions {
        apiURL
        apikey
      }
    }
  }
`
